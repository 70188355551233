import React from 'react';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';

import App from './App';

import NotFound from './routes/NotFound';
import Agreement from './components/Agreement';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/privacy-policy",
    element: <Agreement 
      title="Privacy Policy"
      source="/Agreements/PrivacyPolicy.html"
    />,
  },
  {
    path: "/terms-and-conditions",
    element: <Agreement 
      title="Terms and Conditions"
      source="/Agreements/TermsAndConditions.html"
    />,
  },
  {
    path: "*",
    element: <NotFound />
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

