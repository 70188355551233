import "../App.css";

import React from 'react';
import { Navbar } from "./Navbar/Navbar";
import { Footer } from "./footer";

const resizeIFrame = (obj) => {
    obj.target.style.height = obj.target.contentWindow.document.documentElement.scrollHeight + 'px';
};



export default class Agreement extends React.Component {

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
    
    }
    
    render() {
        return (
            <div>
                <Navbar />
                <div style={{margin: "auto", paddingTop: "100px", paddingBottom: "50px"}}>
                    <iframe 
                        title={this.props.title}
                        src={this.props.source}
                        className="agreement-iframe"
                        onLoad={resizeIFrame}
                    ></iframe>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}