import React from "react";
import "./MainElements.css";
import { CallToAction } from "../CallToAction/CallToAction";
import { ScreensContainer } from "../ProductFeature";

export const MainElements = () => {
  return (
    <div className="container">
      <div className="main-elements-div">
        <div className="left-side-container">
          <div className="text-container">
            <div className="trackr-title">
              Trackr
            </div>
            <div className="trackr-subtext-one">Turn self-improvement<br />into a social game.</div>
            
          </div>

          <CallToAction />
        </div>

        <div className="screens-container">
          <div className="overlap-group">
            <img
              className="app-screenshot"
              alt="App Screenshot"
              src="app_screenshot_1.webp"
            />
            <img
              className="app-img"
              alt="App Screenshot"
              src="app_screenshot_2.webp"
            />
            <div className="rectangle"></div>
          </div>
        </div>
      </div>
      <ScreensContainer></ScreensContainer>
      <div className="line-svg">
        <img
          className="progress-line"
          alt="Progress Line"
          src="progress-line-gradient.svg"
        />
      </div>
    </div>
  );
};
