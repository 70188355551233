export const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: "30px",
        marginBottom: "40px",
        marginTop: "20px",
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
    >
      <a href="/privacy-policy">
        <h5
          style={{
            justifyContent: "center",
            textAlign: "center",
            margin: 0,
          }}>
          Privacy Policy
        </h5>
      </a>
      <a href="/terms-and-conditions">
        <h5
          style={{
            justifyContent: "center",
            textAlign: "center",
            margin: 0,
          }}>
          Terms and Conditions
        </h5>
      </a>
      <h5
        style={{
          justifyContent: "center",
          textAlign: "center",
          margin: 0,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Mailing Address:
        </span>
        <span
          style={{
            fontWeight: "normal",
          }}
        >
          {" "}
          8 The Green STE A Dover, DE 19901, United States
        </span>
      </h5>
      <h5
        style={{
          justifyContent: "center",
          textAlign: "center",
          margin: 0,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Email Address:
        </span>
        <span
          style={{
            fontWeight: "normal",
          }}
        >
          {" "}
          contact@appsbyaurelius.com
        </span>
      </h5>

      <h5
        style={{
          justifyContent: "center",
          textAlign: "center",
          margin: 0,
        }}
      >
        © Aurelius LLC. All rights reserved.
      </h5>
    </div>
  );
};
