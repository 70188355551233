import { Navbar } from "../components/Navbar/Navbar";
import { Footer } from "../components/footer";

const divStyle = {
  textAlign: 'center',
  margin: 'auto',
  paddingTop: '200px', 
  paddingBottom: '100px',
};

function NotFound() {
  return (
    <div>
      <Navbar />
        <div style={divStyle}>
            <h1>404 Not Found</h1>
        </div>
      <Footer></Footer>
      {}
    </div>
  );
}

export default NotFound;