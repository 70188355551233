import React, { useEffect }  from "react";
import "../MainElements/MainElements.css";

export const CallToAction = ({ className }) => {
    return (
        <div className={`app-download-icons-padding ${className}`}>
            <div className="app-download-icons-container">
                <p className="select-device-text">
                    Select your platform
                </p>
                <div className="app-download-icons">

                    <div className="download-store-icon">
                        <a href="https://apps.apple.com/us/app/trackr-social-media/id6469200230" target="_blank">
                            <img 
                                alt="Download on the App Store"
                                src="download_app_store_icon.svg"
                                style={{float: "right"}}
                                className="app-store-icon"
                            />
                        </a>
                    </div>

                    <div className="download-store-icon" style={{position: "relative"}}>
                        <img
                            alt="Download on the Google Play Store"
                            src="google_play_badge.png"
                            style={{float: "left", opacity: ".2"}}
                            className="app-store-icon"
                        />
                        <p className="android-coming-soon-text">Coming Soon!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}