import "./App.css";
import { Navbar } from "./components/Navbar/Navbar";
import { MainElements } from "./components/MainElements/MainElements";
import { Footer } from "./components/footer";

function App() {
  return (
    <div>
      <Navbar />
      <MainElements />
      <Footer></Footer>
      {}
    </div>
  );
}

export default App;
